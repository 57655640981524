<template>
    <footer class="footer">
      <div class="container">
        <p class="text-center">&copy; 2023 <br> 奇迹量子(北京)科技有限公司. All rights reserved.</p>
        <p style="font-size: xx-small; color: gray" class="text-center">2023/11/22 builded</p>
      </div>
    </footer>
  </template>
  
  <style scoped>
  .footer {
    padding: 20px 0;
    margin-top: 20px;
  }
  
  .text-center {
    text-align: center;
  }
  </style>
  