<template>
  <div class="container">
    <div class="row mb-3 d-flex justify-content-center">
      <img class="px-5 " style="max-height: 10vh; width:auto;" src="../assets/QuantumLogo.png">
    </div>
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">{{ $t('biological_radar_fluctuation_detector') }}</h5>
        <h6 class="card-subtitle mb-2 text-muted">{{ $t('user_information_registration') }}</h6> 
          <div class="row mb-3">
            <languageComp />
          </div>
          <div class="row mb-3">
            <div class="col">
              <label for="name">{{ $t('name_suggestion') }}</label>
              <input v-model="name" type="text" class="form-control" id="name" :placeholder="$t('enter_name')" maxlength="20" required>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col">
              <label for="phone">{{ $t('phone') }}*</label>
              <input v-model="phone" type="tel" class="form-control" id="phone" :placeholder="$t('enter_phone_number')" required>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col">
              <label>{{ $t('gender') }}*</label>
              <div>
                <label>
                  <input v-model="computedGender" type="radio" value="男性"> {{ $t('male') }}
                </label>
              </div>
              <div>
                <label>
                  <input v-model="computedGender" type="radio" value="女性"> {{ $t('female') }}
                </label>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col">
              <label for="birthdate">{{ $t('birthdate') }}*</label>
              <input v-model="birthdate" type="date" class="form-control" :max="minBirthdate" required>
              <small style="color: red; font-weight: bold;">{{ $t('age_recommendation') }}</small>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-6">
              <label for="height">{{ $t('height') }}* cm</label>
              <input v-model="height" type="number" class="form-control" id="height" min="150" max="200" required>
            </div>
            <div class="col-6">
              <label for="weight">{{ $t('weight') }}* kg</label>
              <input v-model="weight" type="number" class="form-control" id="weight" min="30" max="120"  required>
            </div>
          </div>
          <div class="row mb-3">
            <button @click="submitForm" class="btn btn-primary btn-block">{{ $t('generate_qr_code') }}</button>
          </div>
          <div v-if="showQRCode" class="row mb-3">
            <div class="card mb-3 p-3" id="memberCard">
              <h5 class="card-title">{{ $t('biological_radar_fluctuation_detector') }}</h5>

              <div class="row g-0 d-flex align-items-center">
                <div class="col-md-4 d-flex justify-content-center align-items-center">
                  <img v-if="computedGender === '男性'" :src="male" class="img-fluid rounded-circle w-50" alt="...">
                  <img v-else-if="computedGender === '女性'" :src="female" class="img-fluid rounded-circle w-50" alt="...">
                </div>
                <div class="col-md-8 ">
                  <div class="card-body">
                    <p class="text-center tight-spacing">{{ $t('name') }}: {{ name }}</p>
                    <p class="text-center tight-spacing">{{ $t('gender') }}: {{ computedGender }}</p>
                    <p class="text-center tight-spacing">{{ $t('birthdate') }}: {{ birthdate }}</p>

                    <div class="mt-3 d-flex justify-content-center align-items-center">
                      <QRCodeVue3 
                        :value="qrCode" 
                        :key="qrCode" 
                        :height="300"
                        :qr-options="{ errorCorrectionLevel: 'H' }" 
                        :image-options="{ hideBackgroundDots: true, imageSize: 0.4, margin: 10 }" 
                        :corners-square-options="{ type: 'dot', color: '#34495E' }" 
                        :corners-dot-options="{ type: undefined, color: '#41B883' }" 
                        :dots-options="{ type: 'dots', color: '#41B883', gradient: { type: 'linear', rotation: 0, colorStops: [ { offset: 0, color: '#41B883' }, { offset: 1, color: '#34495E' } ] } }"  
                        :image="logoImg"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button @click="downloadCardAsJPG" class="btn btn-primary btn-block">{{ $t('download qr code') }}</button>
          </div>
      </div>
    </div>
  </div>
  <!-- 加入版權宣告 footer component -->
  <CopyrightFooter/>
</template>

<script setup>
import { computed, ref } from 'vue'
import QRCodeVue3 from "qrcode-vue3";
import CopyrightFooter from './components/CopyrightFooter'
const logoImg = require('../assets/quantum_logo.svg')
const male = require('../assets/male.png')
const female = require('../assets/female.png')
import html2canvas from 'html2canvas';
/**
 * 會員頭像 URL
 */
import { inject } from 'vue';

// Inject the i18n instance from the grandparent
// eslint-disable-next-line
const i18n = inject('i18n');
import languageComp from '@/components/languageComp.vue';

const name = ref("")
const phone = ref("")
const gender = ref("")
const height = ref("")
const weight = ref("")

const eighteenYearsAgo = new Date();
eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);

const birthdate = ref(eighteenYearsAgo.toISOString().split('T')[0]) 

const qrCode = ref("")
const showQRCode = ref("")


const minBirthdate = computed(() => {
  const eighteenYearsAgo = new Date();
  eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
  return eighteenYearsAgo.toISOString().split('T')[0];
})
const submitForm = (async () => {
  if (name.value === '' || gender.value === '' || birthdate.value === '') {
    alert('請填寫必填欄位');
    return;
  }

  // 檢查年齡是否達到十八歲
  const today = new Date();
  const age = today.getFullYear() - new Date(birthdate.value).getFullYear();

  if (age < 18) {
    alert('您的年齡必須達到18歲以上');
    return;
  }

  qrCode.value = ''; // 清空之前的 QR Code
  showQRCode.value = false;
  const id = await searchCustomerID(name.value, gender.value == 'male' ? '1' : '0', birthdate.value, phone.value, height.value, weight.value)
  let data = {
    name: name.value,
    gender: gender.value,
    birthdate: birthdate.value,
    phone: phone.value,
    id: id
  };
  let encodeText = await getBrotilCompress(data)
  // 生成 QR Code
  if(data.id != undefined && data.id != false){
    qrCode.value = encodeText.compressed_string
    showQRCode.value = true
  }
  else
    alert("条码生成失败")
  
})

import axios from 'axios';

const addCustomer = async (name, gender, birthday, cellphone, weight, height) =>{
  try {
    const data = JSON.stringify({
      "patient_name": name,
      "sex": gender,
      "birthday": birthday.replace('-', '/'),
      "cellphone": cellphone,
      "height": height,
      "weight": weight
    });

    const config = {
      method: 'post',
      url: '/api/addPatient',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
      timeout: 5000
    };

    const response = await axios.request(config);
    if(response.data.Result=="Successful"){
      return response.data.Ind
    }
    else{
      alert(response.data.Result)
      return false
    }
    
  } catch (error) {
    console.log(error);
  }
}
const searchCustomerID = async (name, gender, birthday, cellphone, weight, height) => {
  try {
    const data = JSON.stringify({
      "cellphone": cellphone,
    });

    const config = {
      method: 'post',
      url: '/api/SearchPatient',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
      timeout: 5000

    };

    const response = await axios.request(config);
    if (response.data.length > 0) {
      const result = response.data.find(element => element[6] == cellphone);
      
      if (result) {
        birthdate.value = result[4].replaceAll('/', '-')
        return result[1];
      } else {
        return await addCustomer(name, gender, birthday, cellphone, weight, height);
      }
    } else {
      return await addCustomer(name, gender, birthday, cellphone, weight, height);
    }
    
  } catch (error) {
    console.log(error);
  }
}
const getBrotilCompress = async (objInfo) => {
  try {
    let content = JSON.stringify(objInfo);
    let data = JSON.stringify({
      "input_string": content
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: '/brotil/compress',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    const response = await axios.request(config);
    console.log(JSON.stringify(response.data));

    return response.data; // 可以选择返回响应的数据
  } catch (error) {
    console.log(error);
    throw error; // 可以选择抛出错误，以便在调用该函数的地方处理
  }
};
/**
 * 創建一個 computed 屬性 computedGender，對 gender 進行轉換
 * @type {import('vue').ComputedRef<string>}
 */
 const computedGender = computed({
  get: () => {
    return gender.value === 'male' ? '男性' : gender.value === 'female' ? '女性' : '';
  },
  set: (newValue) => {
    gender.value = newValue === '男性' ? 'male' : newValue === '女性' ? 'female' : '';
  }
});
// const getBrotilDecompress = async (text) => {
//   try {
//     let data = JSON.stringify({
//       "compressed_string": text
//     });

//     let config = {
//       method: 'post',
//       maxBodyLength: Infinity,
//       url: '/brotil/compress',
//       headers: {
//         'Content-Type': 'application/json'
//       },
//       data: data
//     };

//     const response = await axios.request(config);
//     console.log(JSON.stringify(response.data));

//     return response.data; // 可以选择返回响应的数据
//   } catch (error) {
//     console.log(error);
//     throw error; // 可以选择抛出错误，以便在调用该函数的地方处理
//   }
// };
/**
 * 將卡片轉換為JPG並下載
 * @async
 */
 const downloadCardAsJPG = async () => {
  const cardElement = document.querySelector('#memberCard'); // 選擇你想要轉換的div
  if (!cardElement) return;
  // 找到卡片裡的所有圖像元素
  const imgElements = cardElement.querySelectorAll('img');
  
  // 手動加載每一個圖像，並確保它們都加載完成
  const loadPromises = Array.from(imgElements).map(imgElement => {
    console.log(imgElement)
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = resolve;
      img.src = imgElement.src;
    });
  });

  // 等待所有圖像加載完成
  await Promise.all(loadPromises);
  
  const canvas = await html2canvas(cardElement);
  const imgData = canvas.toDataURL('image/jpeg');
  const link = document.createElement('a');
  
  link.href = imgData;
  link.download = 'card.jpg';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
</script>


<style scoped>
  .container{
    padding: 20px;
  }
  .card {
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 5px;
  }

  .card-title {
    text-align: center;
    font-weight: bold;
  }
  .card-subtitle {
    text-align: center;
    font-weight: bold;
  }
  .tight-spacing {
    margin-bottom: 0.2rem;
  }
</style>