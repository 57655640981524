<template>
  <div class="col-6"></div>
  <div class="col-6 d-flex justify-content-end">
    <select class="form-select" aria-label="Language select" @change="changeLocale" v-model="selectedLocale">
      <option value="tw">繁體中文</option>
      <option value="en">English</option>
      <option value="cn">简体中文</option>
      <option value="jp">日本語</option>
    </select>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { inject } from 'vue'; // Import inject to access the i18n instance

// Inject the i18n instance from the parent component
const i18n = inject('i18n');

// eslint-disable-next-line
const { locale, t } = useI18n(i18n);

const selectedLocale = ref(locale.value);

const changeLocale = () => {
  locale.value = selectedLocale.value;
};
</script>
